import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import instagramLogo from "../assets/instagram-logo.png"; // Instagram logo
import yourPhoto from "../assets/your-photo.JPG"; // Your profile image
import youtubeLogo from "../assets/youtube-logo.png";
import catPhoto from "../assets/sushi.jpg"; // Cat image

const Home = () => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [showShowsModal, setShowShowsModal] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [showCat, setShowCat] = useState(false); // Toggle Cat Image
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const shows = [
    { date: "22", month: "Mar", year: "2025", time: "5:35 PM", venue: "Broadway Comedy Club", city: "New York, NY", address: "318 W 53rd St" },
    { date: "24", month: "Mar", year: "2025", time: "7:00 PM", venue: "Broadway Comedy Club", city: "New York, NY", address: "318 W 53rd St" },
  ];
  const sanitizeInput = (str) => str.replace(/[<>]/g, "");
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: sanitizeInput(e.target.value) });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loading spinner
  
    const sanitizedData = {
      name: sanitizeInput(formData.name),
      email: sanitizeInput(formData.email),
      message: sanitizeInput(formData.message).slice(0, 500), // Limit message length
    };
  
    const formBody = new URLSearchParams({
      ...sanitizedData,
      _captcha: "false",
      _next: "https://austintobey.com", // Redirect after submission
    }).toString();
  
    try {
      await fetch("https://formsubmit.co/b526213a552289b51dfb053ea8fc77af", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: formBody,
      });
  
      setFormData({ name: "", email: "", message: "" });
  
      setTimeout(() => {
        setMessageSent(false);
        setShowContactModal(false);
      }, 2000);
    } finally {
      setMessageSent(true)
      setIsLoading(false); 
    }
  };
  
  

  return (
    <div className="d-flex flex-column min-vh-100" style={{ backgroundColor: "#d6d6d6" }}>
      <div className="container d-flex justify-content-center align-items-center flex-grow-1">
        {/* Outer White Box */}
        <div className="shadow-lg p-5 text-center" style={{ maxWidth: "900px", width: "100%", backgroundColor: "#fff", borderRadius: "20px", position: "relative" }}>
          
          {/* Profile Image (Click to Toggle Cat Photo) */}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "20px", cursor: "pointer" }} onClick={() => setShowCat(!showCat)}>
            <img 
              src={showCat ? catPhoto : yourPhoto} 
              alt="Austin Tobey" 
              style={{
                width: "min(450px, 50vw)", // Responsive size
                height: "min(450px, 50vw)", 
                borderRadius: "50%", // Circular
                objectFit: "cover", 
                objectPosition: "center left", 
                display: "block",
                maxWidth: "100%", 
                maxHeight: "100%",
                transition: "opacity 0.3s ease-in-out"
              }} 
            />
          </div>

          {/* Name & Bio */}
          <h1 className="fw-bold mt-3">Austin Tobey</h1>
          <p className="text-muted">New York-based comedian.</p>

          {/* Centered Buttons */}
          <div className="d-flex justify-content-center gap-3 mt-3">
            <button className="btn btn-dark px-4 py-2 fw-bold" onClick={() => setShowContactModal(true)}>Contact</button>
            <button className="btn btn-dark px-4 py-2 fw-bold" onClick={() => setShowShowsModal(true)}>Shows</button>
          </div>

          {/* Social Media Icons (Instagram + YouTube) */}
          <div className="d-flex justify-content-center gap-3 mt-4">
            <a href="https://instagram.com/austintobey" target="_blank" rel="noopener noreferrer">
              <img src={instagramLogo} alt="Instagram" width="40" height="40" />
            </a>
            <a href="https://www.youtube.com/channel/UC6ADzCOnqppbPByflXXleJA" target="_blank" rel="noopener noreferrer">
              <img src={youtubeLogo} alt="YouTube" width="40" height="40" />
            </a>
          </div>
        </div>
      </div>

      {/* Contact Form Modal */}
{showContactModal && (
  <div 
    className="modal fade show d-block" 
    tabIndex="-1" 
    role="dialog" 
    onClick={() => setShowContactModal(false)} // Click outside to close
  >
    <div 
      className="modal-dialog modal-dialog-centered" 
      onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
    >
      <div 
        className="modal-content text-white rounded-4" 
        style={{ backgroundColor: "rgba(0, 0, 0, 0.9)", backdropFilter: "blur(5px)" }}
      >
        <div className="modal-header border-0">
          <h5 className="modal-title text-uppercase fw-bold">Get In Touch</h5>
          <button type="button" className="btn-close btn-close-white" onClick={() => setShowContactModal(false)}></button>
        </div>
        <div className="modal-body">
          {messageSent ? (
            <p className="text-center fw-bold text-success mt-3">Message Sent! I'll get back to you soon.</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label fw-bold">Your Name</label>
                <input 
                  type="text" 
                  name="name" 
                  className="form-control border-0 p-3 rounded-3" 
                  placeholder="Name" 
                  value={formData.name} 
                  onChange={handleChange} 
                  required 
                  disabled={isLoading} // Disable while loading
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-bold">Your Email</label>
                <input 
                  type="email" 
                  name="email" 
                  className="form-control border-0 p-3 rounded-3" 
                  placeholder="you@example.com" 
                  value={formData.email} 
                  onChange={handleChange} 
                  required 
                  disabled={isLoading}
                />
              </div>
              <div className="mb-3">
                <label className="form-label fw-bold">Your Message</label>
                <textarea 
                  name="message" 
                  className="form-control border-0 p-3 rounded-3" 
                  placeholder="Write your message here..." 
                  rows="4" 
                  value={formData.message} 
                  onChange={handleChange} 
                  required 
                  disabled={isLoading}
                ></textarea>
              </div>
              <button 
                type="submit" 
                className="btn btn-success w-100 p-3 fw-bold rounded-3" 
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? (
                  <div className="spinner-border spinner-border-sm text-light" role="status">
                    <span className="visually-hidden">Sending...</span>
                  </div>
                ) : (
                  "Send Message"
                )}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  </div>
)}


      {/* Shows Modal */}
      {showShowsModal && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog" onClick={() => setShowShowsModal(false)}>
          <div className="modal-dialog modal-dialog-centered" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content text-white rounded-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.9)", backdropFilter: "blur(5px)" }}>
              <div className="modal-header border-0">
                <h5 className="modal-title text-uppercase fw-bold">Upcoming Shows</h5>
                <button type="button" className="btn-close btn-close-white" onClick={() => setShowShowsModal(false)}></button>
              </div>
              <div className="modal-body">
                {shows.length > 0 ? (
                  <div className="list-group">
                    {shows.map((show, index) => (
                      <div key={index} className="list-group-item border-0 d-flex align-items-center p-3" style={{ backgroundColor: "transparent" }}>
                        <div className="text-center px-4 py-3 bg-primary text-white fw-bold rounded-start">
                          <div className="fs-2">{show.date}</div>
                          <div className="fs-6">{show.month} {show.year}</div>
                        </div>
                        <div className="flex-grow-1 px-4">
                          <h5 className="fw-bold mb-1 text-white">{show.venue}</h5>
                          <div className="fw-bold text-white">{show.time}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-center">No upcoming shows at the moment.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
